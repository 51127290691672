<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": "Payment",
    "resource": "tenant",
    "action": "manage"
  }
}
</route>
<template>
  <div class="organisation">
    <OrganisationPaymentView />
  </div>
</template>

<script>
// @ is an alias to /src
import OrganisationPaymentView from '@/modules/organisation/pages/OrganisationPaymentView.vue';

export default {
  name: 'payment',
  components: {
    OrganisationPaymentView,
  },
  layout: 'AppMain',
};
</script>
